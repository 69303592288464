import React, { useState, useEffect } from 'react';
import {
  Tooltip, ChakraProvider, Box, Text, Heading, Table, Thead, Tbody, Tr, Th, Td, Input, FormControl, FormLabel, Flex, InputLeftElement, theme, InputGroup, Select
} from '@chakra-ui/react';
import { InfoOutlineIcon } from "@chakra-ui/icons";
import ReactGA from "react-ga4";
import { calculateTaxDelaware, calculateTaxEstonia, calculateTaxHongKong, calculateTaxIreland, calculateTaxNetherlands, calculateTaxOman, calculateTaxSingapore, calculateTaxUAE, calculateTaxUK, calculateTaxWyoming } from './utils/taxFunctions';

const tableData = [
  {
    country: '🇸🇬 Singapore ',
    oneTimeFees: 350,
    recurringFees: 2676,
    taxRate: calculateTaxSingapore,
    currency: 'SGD',
    code: 'sg',
    conversionRate: 0.75,
    specialCase: 'Activity qualifies for Startup Tax exemption scheme',
  },
  {
    country: '🇦🇪 UAE ',
    oneTimeFees: 0,
    recurringFees: 29350,
    taxRate: calculateTaxUAE,
    currency: 'AED',
    code: 'ae',
    conversionRate: 0.27,
  },
  {
    country: '🇴🇲 Oman ',
    oneTimeFees: 600,
    recurringFees: 1033.33,
    taxRate: calculateTaxOman,
    currency: 'OMR',
    code: 'om',
    conversionRate: 2.6,
    specialCase: 'Special tax rate of 3% applies to SME',
  },
  {
    country: '🇪🇪 Estonia ',
    oneTimeFees: 290,
    recurringFees: 1387,
    taxRate: calculateTaxEstonia,
    currency: 'EUR',
    code: 'ee',
    conversionRate: 1.09,
    specialCase: 'Taxes are applicable only if profits are distributed to shareholders'
  },
  {
    country: '🇺🇸 Delaware ',
    oneTimeFees: 500,
    recurringFees: 300,
    taxRate: calculateTaxDelaware,
    currency: 'USD',
    code: 'us',
    conversionRate: 1,
    specialCase: 'LLCs with non-resident owner are effectively tax free'

  },
  {
    country: '🇺🇸 Wyoming ',
    oneTimeFees: 200,
    recurringFees: 299,
    taxRate: calculateTaxWyoming,
    currency: 'USD',
    code: 'us',
    conversionRate: 1,
    specialCase: 'LLCs with non-resident owner are effectively tax free'

  },
  {
    country: '🇳🇱 Netherlands',
    oneTimeFees: 1499,
    recurringFees: 468,
    taxRate: calculateTaxNetherlands,
    currency: 'EUR',
    code: 'nl',
    conversionRate: 1.09,
    specialCase: 'Activity qualifies for innonvation box scheme'

  },
  {
    country: '🇬🇧 United Kingdom',
    oneTimeFees: 165,
    recurringFees: 150,
    taxRate: calculateTaxUK,
    currency: 'GBP',
    code: 'gb',
    conversionRate: 1.25,
    specialCase: 'Activity qualifies for innovation box rate of 10%'

  },
  {
    country: '🇮🇪 Ireland',
    oneTimeFees: (445) * (1.21),
    recurringFees: 240 * (1.21),
    taxRate: calculateTaxIreland,
    currency: 'EUR',
    code: 'ie',
    conversionRate: 1.09,
  },
  {
    country: '🇭🇰 Hong Kong',
    oneTimeFees: 7790,
    recurringFees: 1100 + 1100 + 500 + 150 + 105,
    taxRate: calculateTaxHongKong,
    currency: 'HKD',
    code: 'hk',
    conversionRate: 0.13,
  },

]

export function App() {

  useEffect(() => {
    ReactGA.initialize('G-L24T32ZV4M');
    ReactGA.send({ hitType: "pageview", page: "/home", title: "RemoteBiz" });
    console.log('initialized')
  }, []);

  const [formData, setFormData] = useState({
    ownerCitizenCountry: 'usa',
    totalIncome: 100000,
    expenses: 20000,
    hasCapitalGains: false,
    isSME: true,
    profitSource: 'saas',
  });
  const [sortDirection, setSortDirection] = useState("asc"); // or "desc"
  const [sortColumn, setSortColumn] = useState(""); // column key to sort by

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    console.log("name :", name)
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  return <ChakraProvider theme={theme}>
    <Box
      bg="gray.50"
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center" justifyItems={"center"}>
        < br />
        < br />
        <Heading as="h1" size="2xl" mb={4}>
          🌎 Remote Business Setup Cost Calculator
        </Heading>
        <Heading as="h2" size="lg" fontWeight="extra-thin">
          Instantly estimate profits, taxes, and expenses for Company Setup in Top Business Regions Remotely
        </Heading>
        <br />
        <br />

        <Flex justifyContent="center" mb={4}>

          <Flex alignItems="center" ml={4} mr={4} mb={2}>
            <FormControl>
              <FormLabel>Total Income</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  fontSize="1.2em"
                  children="$"
                />
                <Input
                  type="number"
                  placeholder="Total Income"
                  size="md"
                  width="200px"
                  name="totalIncome"
                  value={formData.totalIncome}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex alignItems="center" ml={4} mb={2}>
            <FormControl>
              <FormLabel>Expenses</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.500"
                  fontSize="1.2em"
                  children="$"
                />
                <Input
                  type="number"
                  placeholder="Expenses"
                  size="md"
                  width="200px"
                  name="expenses"
                  value={formData.expenses}
                  onChange={handleChange}
                />
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex alignItems="center" ml={4} mr={4} mb={2}>
            <FormControl>
              <FormLabel>Main activity of company</FormLabel>
              <Select
                placeholder="Select an activity"
                name="profitSource"
                value={formData.profitSource}
                onChange={handleChange}
                width="200px"
              >
                <option value="saas">SaaS</option>
                <option value="patent">Patent Royalties</option>
                <option value="ecom">Ecommerce</option>
                <option value="holdingco">Holding company</option>
                <option value="propertydev">Property Development</option>
                <option value="others">Others</option>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </Flex>
          <Flex alignItems="center" ml={4} mr={4} mb={2}>
            {/* Dropdown for resident country */}
            <FormControl>
              <FormLabel>Citizenship of Owner</FormLabel>
              <Select
                placeholder="Select a Country"
                name="ownerCitizenCountry"
                value={formData.ownerCitizenCountry}
                onChange={handleChange}
                width="200px"
              >
                <option value="usa">USA</option>
                <option value="others">Others</option>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </Flex>

        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Location</Th>
              {/* <Th>1x Fees (USD)</Th> */}
              <Th>Annual Fees (USD)</Th>
              <Th>Tax Owed (USD)</Th>
              <Th>Corporate Tax Rate</Th>
              <Th>Effective Tax Rate</Th>
              <Th>Total Cost</Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableData.map(function (row, index) {
              const { country, taxRate, oneXFeesUSD, recurringFeesUSD, taxOwedUSD, effectiveTaxRate, totalCost, showTooltip } = generateData(formData, row);
              return (
                <Tr key={index}>
                  <Td>{country}</Td>
                  <Td>
                    <span>
                      ${(recurringFeesUSD + oneXFeesUSD).toFixed(0)}
                    </span>
                  </Td>
                  <Td>
                    <span>
                      ${taxOwedUSD.toFixed(0)}
                    </span>
                  </Td>
                  <Td>
                    {(taxRate).toFixed(2)}%
                    {showTooltip && <Tooltip label={row.specialCase} hasArrow bg="gray.700" color="white">
                      <InfoOutlineIcon ml={2} color="darkgray" boxSize={4} />
                    </Tooltip>}
                  </Td>
                  <Td>
                    {effectiveTaxRate.toFixed(2)}%
                  </Td>
                  <Td>
                    ${totalCost.toFixed(0)}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex justifyContent="center" alignItems="center" mt={4}>
          <a
            href="https://www.producthunt.com/posts/remote-business-setup-calculator?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-remote-business-setup-calculator"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=394286&theme=light"
              alt="Remote Business Setup Calculator - Estimate costs for Company Setup in Top Business Regions 🌏 | Product Hunt"
              style={{ width: '250px', height: '54px' }}
              width="250"
              height="54"
            />
          </a>
        </Flex>
        <br />
        <br />
      </Box>
    </Box>
  </ChakraProvider>
}

const generateData = (formData: any, details: any) => {
  const { country, oneTimeFees, recurringFees, taxRate: taxRateFn, conversionRate } = details;
  const { totalIncome, expenses } = formData;
  const profit = totalIncome > expenses ? totalIncome - expenses : 0;
  const convertedAmount = profit * conversionRate;
  const oneXFeesLocal = oneTimeFees;
  const oneXFeesUSD = oneTimeFees * conversionRate;
  const recurringFeesLocal = recurringFees;
  const recurringFeesUSD = recurringFees * conversionRate;
  const { taxes: tax, showTooltip } = taxRateFn(convertedAmount, formData);
  const taxRate = tax * 100 / convertedAmount;
  const taxOwedUSD = tax / conversionRate;
  const totalCost = oneXFeesUSD + recurringFeesUSD + taxOwedUSD;
  const effectiveTaxRate = profit > 0 ? (totalCost) * 100 / profit : 0;
  return {
    country,
    totalIncome,
    expenses,
    profit,
    convertedAmount,
    oneXFeesLocal,
    oneXFeesUSD,
    recurringFeesLocal,
    recurringFeesUSD,
    taxRate,
    tax,
    taxOwedUSD,
    effectiveTaxRate,
    totalCost,
    conversionRate,
    showTooltip
  }
}