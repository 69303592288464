interface FormData {
    // residentCountry: string;
    totalIncome: number;
    expenses: number;
    hasCapitalGains: boolean;
    isSME: boolean;
    profitSource: string;
    // mainPurposeOfCompany: string;
    ownerCitizenCountry: string;
}

export function calculateTaxUAE(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    const incomeLimit = 375000;
    const taxRate = 0.09;

    if (profit <= incomeLimit) {
        return { taxes: 0, showTooltip: false };
    } else {
        return { taxes: (profit - incomeLimit) * taxRate, showTooltip: false };
    }
}

export function calculateTaxSingapore(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    // Extract relevant values from formData
    const { profitSource } = formData;
    const isStartup = profitSource !== 'propertydev' && profitSource !== 'holdingco';
    const flatRate = 0.17;

    let taxExemptAmount = 0;

    if (isStartup) {
        if (profit <= 100000) {
            taxExemptAmount = profit * 0.75;
        } else if (profit <= 200000) {
            taxExemptAmount = 100000 * 0.75 + (profit - 100000) * 0.5;
        } else {
            taxExemptAmount = 100000 * 0.75 + 100000 * 0.5 + (profit - 200000);
        }
    } else {
        if (profit <= 10000) {
            taxExemptAmount = profit * 0.75;
        } else if (profit <= 200000) {
            taxExemptAmount = 7500 + (profit - 10000) * 0.5;
        } else {
            taxExemptAmount = 10000 * 0.75 + 190000 * 0.5 + (profit - 200000);
        }
    }
    let taxableAmount = profit - taxExemptAmount;
    taxableAmount = taxableAmount > 0 ? taxableAmount : 0;  // Ensure taxable amount is not less than 0
    let taxAmount = taxableAmount * flatRate;

    return { taxes: taxAmount, showTooltip: isStartup };
}



export function calculateTaxOman(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    // Extract relevant values from formData
    //assume registered capital is minimal and not about 50k OMR
    // assume all employees are contractors
    const { totalIncome } = formData;
    const standardRate = 0.15;
    const smeRate = 0.03;

    if (totalIncome <= 100000) {
        return { taxes: profit * smeRate, showTooltip: true };
    } else {
        return { taxes: profit * standardRate, showTooltip: true };
    }
}

export function calculateTaxEstonia(distributedProfit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    // Only personal salary is required, so no changes needed here
    const CITRate = 0.2;

    if (distributedProfit <= 0) {
        return { taxes: 0, showTooltip: true };
    }

    let taxableAmount = distributedProfit;
    let taxAmount = CITRate * taxableAmount;

    return { taxes: taxAmount, showTooltip: true };
}

export function calculateTaxIreland(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    const tradingRate = 0.125;
    const capitalGainsRate = 0.33;

    // Extract relevant values from formData
    const { hasCapitalGains } = formData;

    if (hasCapitalGains) {
        return { taxes: profit * capitalGainsRate, showTooltip: false };
    } else {
        return { taxes: profit * tradingRate, showTooltip: false };
    }
}


export function calculateTaxNetherlands(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    // Extract relevant values from formData
    const { profitSource } = formData;
    const isEligibleForInnovationBox = profitSource === 'patent' || profitSource === 'saas';

    const lowerRateThreshold = 200000;
    const lowerRate = 0.15;
    const standardRate = 0.258;
    const innovationBoxRate = 0.09;

    let taxAmount = 0;

    if (isEligibleForInnovationBox) {
        return { taxes: profit * innovationBoxRate, showTooltip: true };
    }

    if (profit <= lowerRateThreshold) {
        taxAmount = profit * lowerRate;
    } else {
        const lowerRateTax = lowerRateThreshold * lowerRate;
        const remainingProfit = profit - lowerRateThreshold;
        const standardRateTax = remainingProfit * standardRate;
        taxAmount = lowerRateTax + standardRateTax;
    }

    return { taxes: taxAmount, showTooltip: false };
}

export function calculateTaxUK(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    // Extract relevant values from formData
    const { profitSource } = formData;
    const hasPatentExploitation = profitSource === 'patent';

    const lowerLimit = 50000;
    const upperLimit = 250000;
    const lowerRate = 0.19;
    const upperRate = 0.25;
    const effectiveRate = 0.265;

    const patentExploitationRate = 0.1;

    if (hasPatentExploitation) {
        return { taxes: profit * patentExploitationRate, showTooltip: true };
    } else if (profit <= lowerLimit) {
        return { taxes: profit * lowerRate, showTooltip: false };
    } else if (profit <= upperLimit) {
        return { taxes: lowerLimit * lowerRate + (profit - lowerLimit) * effectiveRate, showTooltip: false };
    }
    return { taxes: lowerLimit * lowerRate + (upperLimit - lowerLimit) * effectiveRate + (profit - upperLimit) * upperRate, showTooltip: false };
}

export function calculateTaxHongKong(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    const normalRate = 0.165;
    const smallProfitsRate = 0.0825;

    let taxAmount = 0;

    if (profit <= 2000000) {
        taxAmount = profit * smallProfitsRate;
    } else {
        const smallProfitsTax = 2000000 * smallProfitsRate;
        const remainingProfit = profit - 2000000;
        const normalRateTax = remainingProfit * normalRate;
        taxAmount = smallProfitsTax + normalRateTax;
    }

    return { taxes: taxAmount, showTooltip: false };
}

export function calculateTaxWyoming(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    const { ownerCitizenCountry } = formData;
    const isUSCitizen = ownerCitizenCountry === 'usa';

    if (!isUSCitizen) {
        return { taxes: 0, showTooltip: true };
    }

    return { taxes: profit * 0.21, showTooltip: false };
}

export function calculateTaxDelaware(profit: number, formData: FormData): { taxes: number, showTooltip: boolean } {
    const { ownerCitizenCountry } = formData;
    const isUSCitizen = ownerCitizenCountry === 'usa';

    if (!isUSCitizen) {
        return { taxes: 0, showTooltip: true };
    }

    return { taxes: profit * 0.21 + profit * 0.087, showTooltip: false };
}
